<template>
  <div class="pb-10">
    <!-- <atoms-title class="mb-5">
      {{ targetArea ? `Area ${targetArea.title} -` : "" }}
      {{ (!!targetLocation && "Perbarui") || "Tambah" }} Lokasi</atoms-title
    > -->
    <v-form
      v-if="getCoordinatePosition && getCoordinatePosition.lat"
      ref="form"
      v-model="valid"
      @submit.prevent="preValidate"
    >
      <gmap-map
        :center="getCoordinatePosition"
        :zoom="17"
        style="width: 100%; height: 500px"
        class="rounded-lg mb-5"
        :options="{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: true,
          disableDefaultUi: false,
        }"
      >
        <gmap-marker
          :position="getCoordinatePosition"
          :clickable="true"
          :draggable="true"
          @dragend="onDraggedEnd"
          @click="center = getCoordinatePosition"
        />
        <gmap-circle
          :radius="200"
          :center="getCoordinatePosition"
          :options="{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
          }"
        />
      </gmap-map>
      <div class="pa-2 mb-5">
        <atoms-text>Latitude : {{ getCoordinatePosition.lat }}</atoms-text>
        <atoms-text>Longitude : {{ getCoordinatePosition.lng }}</atoms-text>
      </div>

      <!-- Handle Client -->
      <v-row v-if="!targetArea && !targetLocation" justify="space-between" class="my-2">
        <v-col cols="12" md="auto" class="grow">
          <v-form
            v-if="!client.enabled"
            @submit.prevent="
              () => {
                getClients()
                client.enabled = true
              }
            "
          >
            <atoms-text-field
              class="rounded-lg"
              label="Cari Client"
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-magnify"
              v-model.trim="client.term"
              :enter="
                () => {
                  getClients()
                  client.enabled = true
                }
              "
              :appendAction="
                () => {
                  getClients()
                  client.enabled = true
                }
              "
            ></atoms-text-field>
          </v-form>
          <atoms-text-field v-else label="Cari Client">
            <v-row dense>
              <v-col cols="1" md="auto" class="shrink">
                <atoms-button
                  icon
                  @click="
                    () => {
                      client = {
                        enabled: false,
                        item: null,
                        items: null,
                        term: '',
                      }
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11" md="auto" class="grow">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.item"
                  :items="client.items"
                  item-text="name"
                  :item-value="(obj) => obj"
                  :placeholder="` ${client.term ? `Pencarian Sekarang : ${client.term}.` : ''} Pilih Client (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
      </v-row>
      <!-- Handle Area -->
      <v-row justify="space-between" class="my-2">
        <v-col cols="12" md="auto" class="grow">
          <v-form
            v-if="!area.enabled"
            @submit.prevent="
              () => {
                getArea()
                area.enabled = true
              }
            "
          >
            <atoms-text-field
              class="rounded-lg"
              label="Cari Area"
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-magnify"
              v-model.trim="area.term"
              :disabled="!client.item"
              :enter="
                () => {
                  getArea()
                  area.enabled = true
                }
              "
              :appendAction="
                () => {
                  getArea()
                  area.enabled = true
                }
              "
            ></atoms-text-field>
          </v-form>
          <atoms-text-field v-else label="Cari Area">
            <v-row dense>
              <v-col cols="1" md="auto" class="shrink">
                <atoms-button
                  icon
                  @click="
                    () => {
                      area = {
                        enabled: false,
                        item: null,
                        items: null,
                        term: '',
                      }
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11" md="auto" class="grow">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="area.item"
                  :items="area.items"
                  item-text="title"
                  :item-value="(obj) => obj"
                  :placeholder="` ${area.term ? `Pencarian Sekarang : ${area.term}.` : ''} Pilih Area (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
      </v-row>
      <gmap-autocomplete class="introInput" @place_changed="addressOnChange" :select-first-on-enter="true">
        <template v-slot:default="slotProps">
          <div class="d-flex justify-space-between">
            <v-label>
              <atoms-text class="pb-1 px-2 font-weight-bold text-left">Alamat Lokasi</atoms-text>
            </v-label>
          </div>
          <v-text-field
            class="rounded-lg"
            inner-icon="marker"
            placeholder="Masukkan Alamat Lokasi..."
            v-model="address"
            ref="input"
            v-on:listeners="slotProps.listeners"
            v-on:attrs="slotProps.attrs"
            hide-details
            required
            :disabled="!client.item || !area.item"
            dense
            solo
            flat
          >
          </v-text-field>
        </template>
      </gmap-autocomplete>

      <atoms-text-field
        label="Nama Lokasi"
        v-model="name"
        class="mt-5"
        :disabled="$store.state.loading || !client.item || !area.item"
        :rules="[
          (v) => !!v || 'Harap diisi!',
          (v) => (!!v && duplicate !== v) || '(Nama sudah terdaftar) gunakan nama lain!',
        ]"
      />
      <div class="d-flex justify-start">
        <div :class="['d-flex justify-left']">
          <v-checkbox v-model="forceGeotagging" class="ma-0 pa-0" />
          <atoms-text class="text-left">Pekerja Wajib Absensi di Lokasi</atoms-text>
        </div>
      </div>
      <atoms-text-field label="Maksimum Jangkauan Radius Absensi">
        <v-row dense>
          <v-col>
            <v-select
              class="rounded-lg"
              v-model.trim="maxDistance"
              :items="listMaxDistances"
              :menu-props="{ offsetY: true }"
              item-text="label"
              item-value="value"
              hide-details
              dense
              solo
              flat
            />
          </v-col>
        </v-row>
      </atoms-text-field>
      <atoms-button
        :disabled="!client.item || !area.item"
        :loading="$store.state.loading"
        type="submit"
        :style="{ width: '100%' }"
        class="primary mt-5"
        >{{ targetLocation ? 'Perbarui' : 'Simpan' }} Lokasi</atoms-button
      >
    </v-form>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
const defaultPos = { lat: -6.1624923, lng: 106.874431 }
export default {
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      targetArea: null,
      targetLocation: null,
      name: '',
      duplicate: '',
      address: '',
      forceGeotagging: false,
      maxDistance: 200,
      listMaxDistances: [],
      inactive: false,
      valid: true,
      isLoaded: false,
      client: {
        enabled: false,
        term: '',
        item: null,
        items: null,
      },
      area: {
        enabled: false,
        term: '',
        item: null,
        items: null,
      },
      areaFilters: [
        {
          title: 'Nama Client',
          value: (term) => ({
            'client.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Alamat Client',
          value: (term) => ({
            'client.address.streetAddress1': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama PIC',
          value: (term) => ({
            'client.contactPerson.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email PIC',
          value: (term) => ({
            'client.contactPerson.email': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Kode Client',
          value: (term) => ({
            'client.code': { $regex: term, $options: 'i' },
          }),
        },
        {
          title: 'Nama Area',
          value: (term) => ({
            'areas.title': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama Lokasi',
          value: (term) => ({
            'areas.locations': {
              $elemMatch: {
                $or: [{ name: { $regex: term, $options: 'i' } }, { address: { $regex: term, $options: 'i' } }],
              },
            },
          }),
        },
      ],
    }
  },
  computed: {
    getCoordinate() {
      return this.$store.state.gmap.coordinate
    },
    getCoordinatePosition() {
      return {
        lat: this.getCoordinate.lat,
        lng: this.getCoordinate.lng,
      }
    },
  },
  watch: {
    'area.item'(val) {
      this.targetArea = val
    },
  },
  async mounted() {
    this.isLoaded = false
    this.$store.commit('SET', { loading: true })
    try {
      await this.$gmapApiPromiseLazy()
      this.targetLocation = this.$route.query.targetLocation
      this.targetArea = this.$route.query.targetArea
      if (this.targetArea && this.targetLocation) {
        this.client.item = {};
      }
      if (this.targetArea) {
        const resArea = await this.$store.dispatch('area/getAll', {
          query: {
            _id: this.targetArea,
          },
          returnOnly: true,
        })
        this.targetArea = resArea?.data?.[0]
        this.area.items = [this.targetArea]
        this.area.item = this.targetArea
        this.area.enabled = true
      }
      if (this.targetLocation) {
        const resLocation = await this.$store.dispatch('location/getAll', {
          query: {
            _id: this.targetLocation,
          },
          returnOnly: true,
        })
        this.targetLocation = resLocation?.data?.[0]
        const { name, address, forceGeotagging, maxDistance, inactive, coordinate } = this.targetLocation
        this.address = address
        this.name = name
        this.forceGeotagging = forceGeotagging || false
        this.maxDistance = maxDistance || 200
        // this.inactive = inactive || false;
        this.listMaxDistances = await this.$api.get('Locations/getListMaxDistances').then((res) => res.result)
        this.$store.commit('gmap/setCoordinate', {
          lat: coordinate?.latitude || defaultPos.lat,
          lng: coordinate?.longitude || defaultPos.lng,
        })
      }
      this.isLoaded = true
    } finally {
      this.$store.commit('SET', { loading: false })
    }
  },
  methods: {
    async getClients() {
      this.$store.commit('SET', { loading: true })
      try {
        const matchQuery = {}
        if (this?.client?.term) {
          matchQuery.name = {
            $regex: this.client.term || '',
            $options: 'i',
          }
        }
        if (this?.client?.item?._id) {
          matchQuery._id = this.client.item._id
        }
        this.client.items = await this.$store.dispatch('client/getAll', {
          custom: {
            pipeline: [
              {
                $match: matchQuery,
              },
            ],
          },
          returnOnly: true,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getArea() {
      this.$store.commit('SET', { loading: true })
      try {
        this.area.items = (
          await this.$store.dispatch('area/getAll', {
            custom: {
              clientId: this.client.item?._id,
              pipeline: [
                {
                  $lookup: {
                    from: 'Clients',
                    localField: 'clientId',
                    foreignField: '_id',
                    as: 'client',
                  },
                },
                {
                  $unwind: {
                    path: '$client',
                    preserveNullAndEmptyArrays: true,
                  },
                },

                {
                  $lookup: {
                    from: 'Locations',
                    localField: '_id',
                    foreignField: 'areaId',
                    as: 'locations',
                  },
                },
                {
                  $addFields: {
                    areas: '$$ROOT',
                  },
                },
                {
                  $match: {
                    $or: [...this.areaFilters.map((x) => x?.value?.(this.area.term))],
                  },
                },
              ],
            },
          })
        )?.data
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    addressOnChange(event) {
      this.$store.commit('SET', { loading: true })

      const {
        address_components,
        geometry: { location },
      } = event
      this.address = address_components?.map((it) => it.long_name + ' ')?.toString()
      this.$store.commit('gmap/setCoordinate', {
        lat: location?.lat() || defaultPos.lat,
        lng: location?.lng() || defaultPos.lng,
      })
      this.$store.commit('SET', { loading: false })
    },
    async onDraggedEnd(event) {
      this.$store.commit('SET', { loading: true })
      const pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      this.$store.commit('gmap/setCoordinate', {
        lat: pos?.lat || defaultPos.lat,
        lng: pos?.lng || defaultPos.lng,
      })

      const reverseGeo = await (
        await fetch(
          `https://us1.locationiq.com/v1/reverse?key=${process.env.VUE_APP_LOCATIONIQ_KEY}&lat=${pos.lat}&lon=${pos.lng}&format=json`,
        ).catch((err) =>
          Promise.reject(
            'Terdapat masalah ketika mendapatkan alamat dari titik pada map. Gunakan kolom autocomplete `Alamat Lokasi` untuk mengatur titik pada map',
          ),
        )
      ).json()

      if (reverseGeo) {
        this.address = reverseGeo?.display_name
      }

      // @reverse by google maps api
      //   const geocoder = new google.maps.Geocoder();
      //   geocoder.geocode({ location: pos }, (results, status) => {
      //     if (status === "OK") {
      //       console.log(results);
      //       // this.currentLocation.lat = results[0].geometry.location.lat();
      //       // this.currentLocation.lng = results[0].geometry.location.lng();
      //     }
      //   });
      this.$store.commit('SET', { loading: false })
    },
    async preValidate() {
      this.$store.commit('SET', { loading: true })
      if (!this.valid || !this.name || !this.address || !this.targetArea) {
        this.$showDialog({
          title: 'Gagal',
          body: `Pastikan kolom area, nama dan alamat lokasi sudah terisi!`,
        })
        this.$store.commit('SET', { loading: false })
        return
      }

      if (!this.targetLocation) {
        // check for update
        await this.$store
          .dispatch('location/checkLocation', {
            name: { $regex: this.name || '', $options: 'i' },
            areaId: this.targetArea?._id,
          })
          .then(async (res) => {
            if (res) {
              this.duplicate = this.name
            } else {
              this.duplicate = ''
            }
          })
      } else {
        this.duplicate = ''
      }

      await this.validate()

      this.$store.commit('SET', { loading: false })
    },

    async validate() {
      if (!this.$refs.form.validate()) {
        this.$showDialog({
          title: 'Gagal',
          body: `Periksa kembali kolom pengisian`,
        })
        return
      }
      this.$store.commit('SET', { loading: true })
      const body = {
        name: this.name,
        address: this.address,
        areaId: this.targetArea?._id,
        // note
        coordinate: {
          latitude: this.getCoordinate.lat,
          longitude: this.getCoordinate.lng,
        },
        inactive: this.inactive,
        forceGeotagging: this.forceGeotagging,
        maxDistance: this.maxDistance,
      }

      if (!this.targetLocation) {
        await this.$store
          .dispatch('location/create', {
            _id: this.$uuid.v4(),
            ...body,
          })
          .then((e) => {
            if (e) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Lokasi ${body.name} berhasil ditambahkan!`,
              })
            }
          })
      } else {
        await this.$store
          .dispatch('location/update', {
            _id: this.targetLocation._id,
            ...body,
          })
          .then((e) => {
            if (e) {
              this.$showDialog({
                title: 'Berhasil',
                body: `Lokasi ${body.name} berhasil diperbarui!`,
              })
            }
          })
      }

      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.area = {
        enabled: false,
        item: null,
        items: null,
        term: '',
      }
      this.$store.commit('SET', { loading: false })
      this.$router.push('/areas/location')
    },
  },
}
</script>
