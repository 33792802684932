var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-10"},[(_vm.getCoordinatePosition && _vm.getCoordinatePosition.lat)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.preValidate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('gmap-map',{staticClass:"rounded-lg mb-5",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.getCoordinatePosition,"zoom":17,"options":{
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
      }}},[_c('gmap-marker',{attrs:{"position":_vm.getCoordinatePosition,"clickable":true,"draggable":true},on:{"dragend":_vm.onDraggedEnd,"click":function($event){_vm.center = _vm.getCoordinatePosition}}}),_c('gmap-circle',{attrs:{"radius":200,"center":_vm.getCoordinatePosition,"options":{
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        }}})],1),_c('div',{staticClass:"pa-2 mb-5"},[_c('atoms-text',[_vm._v("Latitude : "+_vm._s(_vm.getCoordinatePosition.lat))]),_c('atoms-text',[_vm._v("Longitude : "+_vm._s(_vm.getCoordinatePosition.lng))])],1),(!_vm.targetArea && !_vm.targetLocation)?_c('v-row',{staticClass:"my-2",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[(!_vm.client.enabled)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return (() => {
              _vm.getClients()
              _vm.client.enabled = true
            }).apply(null, arguments)}}},[_c('atoms-text-field',{staticClass:"rounded-lg",attrs:{"label":"Cari Client","placeholder":"Ketikkan kata kunci pencarian...","appendIcon":"mdi-magnify","enter":() => {
                _vm.getClients()
                _vm.client.enabled = true
              },"appendAction":() => {
                _vm.getClients()
                _vm.client.enabled = true
              }},model:{value:(_vm.client.term),callback:function ($$v) {_vm.$set(_vm.client, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"client.term"}})],1):_c('atoms-text-field',{attrs:{"label":"Cari Client"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"shrink",attrs:{"cols":"1","md":"auto"}},[_c('atoms-button',{attrs:{"icon":""},on:{"click":() => {
                    _vm.client = {
                      enabled: false,
                      item: null,
                      items: null,
                      term: '',
                    }
                  }}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"11","md":"auto"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":_vm.client.items,"item-text":"name","item-value":(obj) => obj,"placeholder":` ${_vm.client.term ? `Pencarian Sekarang : ${_vm.client.term}.` : ''} Pilih Client (Opsional)`,"menu-props":{ offsetY: true },"hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.client.item),callback:function ($$v) {_vm.$set(_vm.client, "item", $$v)},expression:"client.item"}})],1)],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"my-2",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[(!_vm.area.enabled)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return (() => {
              _vm.getArea()
              _vm.area.enabled = true
            }).apply(null, arguments)}}},[_c('atoms-text-field',{staticClass:"rounded-lg",attrs:{"label":"Cari Area","placeholder":"Ketikkan kata kunci pencarian...","appendIcon":"mdi-magnify","disabled":!_vm.client.item,"enter":() => {
                _vm.getArea()
                _vm.area.enabled = true
              },"appendAction":() => {
                _vm.getArea()
                _vm.area.enabled = true
              }},model:{value:(_vm.area.term),callback:function ($$v) {_vm.$set(_vm.area, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"area.term"}})],1):_c('atoms-text-field',{attrs:{"label":"Cari Area"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"shrink",attrs:{"cols":"1","md":"auto"}},[_c('atoms-button',{attrs:{"icon":""},on:{"click":() => {
                    _vm.area = {
                      enabled: false,
                      item: null,
                      items: null,
                      term: '',
                    }
                  }}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"11","md":"auto"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":_vm.area.items,"item-text":"title","item-value":(obj) => obj,"placeholder":` ${_vm.area.term ? `Pencarian Sekarang : ${_vm.area.term}.` : ''} Pilih Area (Opsional)`,"menu-props":{ offsetY: true },"hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.area.item),callback:function ($$v) {_vm.$set(_vm.area, "item", $$v)},expression:"area.item"}})],1)],1)],1)],1)],1),_c('gmap-autocomplete',{staticClass:"introInput",attrs:{"select-first-on-enter":true},on:{"place_changed":_vm.addressOnChange},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-label',[_c('atoms-text',{staticClass:"pb-1 px-2 font-weight-bold text-left"},[_vm._v("Alamat Lokasi")])],1)],1),_c('v-text-field',{ref:"input",staticClass:"rounded-lg",attrs:{"inner-icon":"marker","placeholder":"Masukkan Alamat Lokasi...","hide-details":"","required":"","disabled":!_vm.client.item || !_vm.area.item,"dense":"","solo":"","flat":""},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,false,205377325)}),_c('atoms-text-field',{staticClass:"mt-5",attrs:{"label":"Nama Lokasi","disabled":_vm.$store.state.loading || !_vm.client.item || !_vm.area.item,"rules":[
        (v) => !!v || 'Harap diisi!',
        (v) => (!!v && _vm.duplicate !== v) || '(Nama sudah terdaftar) gunakan nama lain!',
      ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"d-flex justify-start"},[_c('div',{class:['d-flex justify-left']},[_c('v-checkbox',{staticClass:"ma-0 pa-0",model:{value:(_vm.forceGeotagging),callback:function ($$v) {_vm.forceGeotagging=$$v},expression:"forceGeotagging"}}),_c('atoms-text',{staticClass:"text-left"},[_vm._v("Pekerja Wajib Absensi di Lokasi")])],1)]),_c('atoms-text-field',{attrs:{"label":"Maksimum Jangkauan Radius Absensi"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{staticClass:"rounded-lg",attrs:{"items":_vm.listMaxDistances,"menu-props":{ offsetY: true },"item-text":"label","item-value":"value","hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.maxDistance),callback:function ($$v) {_vm.maxDistance=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"maxDistance"}})],1)],1)],1),_c('atoms-button',{staticClass:"primary mt-5",style:({ width: '100%' }),attrs:{"disabled":!_vm.client.item || !_vm.area.item,"loading":_vm.$store.state.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.targetLocation ? 'Perbarui' : 'Simpan')+" Lokasi")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }